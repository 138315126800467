import styled from "styled-components";

export const ContainerButton = styled.button`
  width: 100%;
  max-width: 325px;
  height: 37px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 17px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const Icon = styled.img`
  width: 35px;
  height: 35px;
`;
