import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import api from "../../api";
import Button from "../button";
import Card from "../card";
import Title from "../title";

import { Container, ContainerButton, ContainerCards, Content } from "./styles";

const Highlights = ({ highlights = true }) => {
  const navigate = useNavigate();

  const renderProperties = async () => {
    const response = await api.get("/buildings", {
      params: {
        building_ids:
          highlights === true
            ? ["30928", "19351", "50647"]
            : ["34578", "19801", "42819", "42651"],
      },
    });

    return response.data.buildings;
  };

  const {
    data = [],
    isLoading,
    error,
  } = useQuery({
    queryKey: [`properties-${highlights && "highlights"}`],
    queryFn: renderProperties,
    staleTime: 60 * 1000 * 5,
  });

  return (
    <Container
      style={{
        backgroundColor: !highlights && "white",
      }}
    >
      <Content>
        <Title
          subtitle={`${
            highlights === true
              ? "Imóveis selecionados pela nossa equipe de especialistas"
              : ""
          }`}
          fontWeight={900}
          fontSize={!highlights && 34}
          color={!highlights && "#606060"}
        >
          {highlights === true
            ? "Destaques que separamos para você"
            : "Oportunidades especiais"}
        </Title>

        <ContainerCards>
          {isLoading && <span>Carregando...</span>}
          {error && <span>Erro</span>}

          {!isLoading &&
            !error &&
            data.map((item, index) => {
              const {
                id,
                name,
                default_image,
                address,
                min_area,
                min_parking,
                min_bedrooms,
                min_price,
              } = item;

              return (
                <Card
                  key={id || index}
                  name={name}
                  image={default_image["520x280"]}
                  dormitory={min_bedrooms}
                  location={address.area}
                  parkingSpaces={min_parking}
                  price={min_price}
                  squareMeters={min_area}
                  suite={0}
                  card={!highlights && "default"}
                  onClick={() => navigate(`/imovel/${item.id}`)}
                />
              );
            })}
        </ContainerCards>

        <ContainerButton>
          <Button
            onClick={() =>
              navigate(
                `${
                  highlights === true
                    ? "/imoveis/destaques"
                    : "/imoveis/oportunidades-especiais"
                }`
              )
            }
          >
            {highlights === true
              ? "+ VEJA TODOS OS DESTAQUES"
              : "+ VEJA MAIS OPÇÕES"}
          </Button>
        </ContainerButton>
      </Content>
    </Container>
  );
};

export default Highlights;
