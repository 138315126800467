import Root from "./pages";
import ResetStyles from "./reset";

function App() {
  return (
    <>
      <ResetStyles />
      <Root />
    </>
  );
}

export default App;
