import React from "react";
import { Container } from "./styles";

const RangeFilter = ({ label, register, type }) => {
  const formatCurrency = (price) => {
    let numericPrice = price.replace(/\D/g, "");

    numericPrice = numericPrice.replace(/(\d)(\d{3})(\d{3})$/, "$1.$2.$3");
    numericPrice = numericPrice.replace(/(\d)(\d{3})$/, "$1.$2");
    numericPrice = numericPrice.replace(/(\d{1,3})(?=(\d{3})+(?!\d))/g, "$1.");

    return numericPrice ? `R$ ${numericPrice}` : "";
  };

  return (
    <Container>
      <label>{label}</label>
      <div>
        <input
          {...register(type === "price" ? "priceTo" : "areaTo")}
          onChange={(e) =>
            type === "price"
              ? (e.target.value = formatCurrency(e.target.value))
              : e.target.value
          }
        />
      </div>
    </Container>
  );
};

export default RangeFilter;
