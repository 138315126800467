import Footer from "../../components/footer";
import Header from "../../components/header";
import Title from "../../components/title";
import { Container, ContainerInformations, Content } from "./styles";

const PrivacyPolicy = () => {
  return (
    <Container>
      <Header />
      <Content>
        <ContainerInformations>
          <Title fontSize={32}>Política de Privacidade</Title>

          <p>
            A ESTOQUE IMÓVEL assume o compromisso de respeitar a legislação
            vigente relativa à proteção de dados pessoais e a não ceder, sob
            qualquer forma ou condição, estas informações a outras entidades.
          </p>

          <br />

          <p>Nós coletamos os seguintes tipos de dados:</p>
          <ul>
            <li>Dados Pessoais</li>
            <li>Dados de Imóveis</li>
          </ul>

          <br />

          <p>
            Utilizamos os dados coletados exclusivamente para o contato direto
            com o usuário, por meio de ligação telefônica, e-mail ou WhatsApp,
            com o objetivo de enviar comunicados relacionados à{" "}
            <>ESTOQUE IMÓVEL</> e oferecer produtos e serviços de interesse.
          </p>

          <br />

          <p>
            Em nenhuma hipótese, a <strong>ESTOQUE IMÓVEL</strong> compartilha
            Dados Pessoais ou de Imóveis com parceiros ou terceiros sem o
            consentimento prévio e expresso do usuário.
          </p>

          <br />

          <p>
            Qualquer pessoa que se cadastrar em qualquer site ou landing page da{" "}
            <strong>ESTOQUE IMÓVEL</strong> terá seus dados protegidos por
            sistemas de segurança. Caso deseje acessar ou remover seus dados do
            sistema, basta enviar um e-mail para contato@estoqueimovel.com.br.
          </p>

          <br />

          <p>
            Utilizamos cookies e tecnologias semelhantes, como pixels e tags,
            para garantir que os serviços prestados e oferecidos atendam ao mais
            alto padrão de desempenho esperado pelo usuário, em ações como
            marketing e estudo do comportamento do usuário nos sites da{" "}
            <strong>ESTOQUE IMÓVEL</strong>.
          </p>

          <br />

          <p>
            Todos os cookies coletados jamais serão utilizados para qualquer
            propósito diferente dos expressamente previstos nesta Política de
            Privacidade.
          </p>

          <br />

          <p>
            A <strong>ESTOQUE IMÓVEL</strong> também se compromete a adotar
            todas as medidas de segurança necessárias para garantir a proteção
            dos dados pessoais dos usuários contra uso indevido ou acesso não
            autorizado.
          </p>

          <br />

          <p>
            A <strong>ESTOQUE IMÓVEL</strong> poderá alterar esta política de
            privacidade e segurança de dados a qualquer momento. Todas as
            alterações serão publicadas neste espaço, portanto, solicitamos a
            leitura periódica desta Política como meio de se informar sobre como
            a <strong>ESTOQUE IMÓVEL</strong> coleta, trata e protege suas
            informações.
          </p>

          <br />

          <p>
            Caso tenha qualquer dúvida ou sugestão sobre esta Política de
            Privacidade e Segurança de Dados, escreva para:
            contato@estoqueimovel.com.br .
          </p>
        </ContainerInformations>
      </Content>
      <Footer />
    </Container>
  );
};

export default PrivacyPolicy;
