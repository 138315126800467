import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

import Input from "../input";
import RangeFilter from "../rangeFilter";
import Status from "../status";
import Button from "../button";
import Border from "../border";

import {
  Container,
  ContainerButton,
  Content,
  FiltersRow,
  Form,
  Title,
} from "./styles";

const Search = () => {
  const navigate = useNavigate();

  const query = new URLSearchParams(useLocation().search);

  const { register, handleSubmit } = useForm({
    defaultValues: {
      neighborhood: query.get("area"),
      city: query.get("city"),
      state: query.get("state"),
      type: query.get("type"),
    },
  });

  const onSubmit = (data) => {
    const {
      type,
      city,
      neighborhood,
      reference,
      priceTo,
      areaTo,
      status,
      state,
    } = data;

    const statusRealestate = Array.isArray(status) ? status.join(",") : status;

    query.set("type", type || "");
    query.set("city", city);
    query.set(
      "area",
      neighborhood.toLowerCase().charAt(0).toUpperCase() + neighborhood.slice(1)
    );
    query.set("reference", reference);
    query.set("max_price", priceTo.replace(/[R\$\s.,]/g, ""));
    query.set("max_private_area", areaTo);
    query.set("status", statusRealestate === false ? "" : statusRealestate);
    query.set("state", state);

    navigate({
      pathname: window.location.pathname,
      search: query.toString(),
    });
  };

  const type = [
    {
      name: "Apartamento",
      id: "apartments",
    },
    {
      name: "Casa",
      id: "house",
    },
  ];

  const states = [
    { name: "Acre", id: "AC" },
    { name: "Alagoas", id: "AL" },
    { name: "Amapá", id: "AP" },
    { name: "Amazonas", id: "AM" },
    { name: "Bahia", id: "BA" },
    { name: "Ceará", id: "CE" },
    { name: "Distrito Federal", id: "DF" },
    { name: "Espírito Santo", id: "ES" },
    { name: "Goiás", id: "GO" },
    { name: "Maranhão", id: "MA" },
    { name: "Mato Grosso", id: "MT" },
    { name: "Mato Grosso do Sul", id: "MS" },
    { name: "Minas Gerais", id: "MG" },
    { name: "Pará", id: "PA" },
    { name: "Paraíba", id: "PB" },
    { name: "Paraná", id: "PR" },
    { name: "Pernambuco", id: "PE" },
    { name: "Piauí", id: "PI" },
    { name: "Rio de Janeiro", id: "RJ" },
    { name: "Rio Grande do Norte", id: "RN" },
    { name: "Rio Grande do Sul", id: "RS" },
    { name: "Rondônia", id: "RO" },
    { name: "Roraima", id: "RR" },
    { name: "Santa Catarina", id: "SC" },
    { name: "São Paulo", id: "SP" },
    { name: "Sergipe", id: "SE" },
    { name: "Tocantins", id: "TO" },
  ];

  return (
    <Container>
      <Content>
        <Title>Que imóvel você procura?</Title>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <FiltersRow>
            <Input
              label="Tipo de imóvel"
              select={true}
              register={register}
              name="type"
              data={type}
            />
            <Input
              label="Estado"
              register={register}
              name="state"
              select={true}
              data={states}
            />
            <Input label="Cidade" register={register} name="city" />
            <Input label="Bairro" register={register} name="neighborhood" />
          </FiltersRow>

          <FiltersRow>
            <RangeFilter label="Valor até" register={register} type="price" />
            <RangeFilter label="Área m² até" register={register} />
            <Status register={register} />
            <Input label="Referência" register={register} name="reference" />
          </FiltersRow>

          <ContainerButton>
            <Button icon="search" backgroundColor="" button="">
              Buscar
            </Button>
          </ContainerButton>
        </Form>
      </Content>
      <Border bottom={0} />
    </Container>
  );
};

export default Search;
