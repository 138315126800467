import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  label {
    font-size: 13px;
    color: #3c3c3b;
  }
  div {
    display: flex;
    gap: 5px;
    align-items: center;
  }

  input {
    width: 100%;
    height: 39px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding-left: 10px;

    &:focus {
      outline: none;
    }
  }
`;
