import { Container } from "./styles";

const Input = ({ label, select = false, register, name, data = [] }) => {
  return (
    <Container>
      <label>{label}</label>

      {select === true ? (
        <select {...register(name)}>
          {data.map((option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </select>
      ) : (
        <input {...register(name)} />
      )}
    </Container>
  );
};

export default Input;
