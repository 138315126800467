import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 9999;
  display: flex;
  justify-content: center;
  padding: 0 20px;

  .rcb-chat-window {
    width: 100%;
    max-width: 687px;
  }
`;

export const Content = styled.div`
  max-width: 1128px;
  width: 100%;
  padding: 35px 0;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  img {
    width: auto;
  }

  h3 {
    color: #fc621f;
    font-size: 16px;
    cursor: pointer;

    @media (max-width: 768px) {
      display: none;
    }
  }

  div {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }
`;

export const Icon = styled.img`
  width: 30px;
  height: 30px;
`;

export const RenderChat = styled.div`
  display: flex;
  justify-content: center;
  figure {
    img {
      width: 100%;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export const Data = styled.div`
  color: #3c3c3b;
  margin-top: 10px;
  margin-left: 50px;
  text-align: start;
`;

export const RenderCards = styled.div`
  padding: 30px 50px;
  width: 100vw;
`;
