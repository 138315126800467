import { useParams } from "react-router-dom";

import Footer from "../../components/footer";
import Header from "../../components/header";
import PropertyList from "../../components/propertyList";
import Title from "../../components/title";

import { Container, Content } from "./styles";

const RenderCategory = () => {
  const { name } = useParams();

  return (
    <Container>
      <Header />
      <Content>
        <Title
          color={name !== "destaques" && "#606060"}
          fontSize={name !== "destaques" && 34}
          subtitle={`${
            name === "destaques"
              ? "Imóveis selecionados pela nossa equipe de especialistas"
              : ""
          } `}
        >
          {name === "obras"
            ? "Em obras"
            : name
                .replace(/-/g, " ")
                .replace(/^./, (char) => char.toUpperCase())}
        </Title>

        <PropertyList queryKey={name} />
      </Content>

      <Footer />
    </Container>
  );
};

export default RenderCategory;
