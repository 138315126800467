import Footer from "../../components/footer";
import Header from "../../components/header";
import Search from "../../components/search";
import Title from "../../components/title";
import PropertyList from "../../components/propertyList";

import { Container, Content } from "./styles";

const SearchResults = () => {
  return (
    <Container>
      <Header />
      <Search />
      <Content>
        <Title fontSize={34}>Resultado da sua pesquisa</Title>

        <PropertyList queryKey="fetchProperties" />
      </Content>

      <Footer />
    </Container>
  );
};

export default SearchResults;
