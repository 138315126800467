import React from "react";

import { Container } from "./styles";

const SidebarItem = ({ children, onClick, href }) => {
  return (
    <Container onClick={onClick} href={href}>
      {children}
    </Container>
  );
};

export default SidebarItem;
