import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  label {
    color: #3c3c3b;
    font-size: 13px;
  }

  div {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  input {
    width: 20px;
    height: 20px;
  }
`;
