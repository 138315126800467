import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  max-width: 1128px;
  width: 100%;
  margin: 0 auto;
  padding: 35px 0;
  min-height: 500px;

  @media (max-width: 1128px) {
    padding: 35px 20px;
  }
`;

export const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;

  @media (max-width: 900px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 650px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 425px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const ContainerButton = styled.div`
  padding: 30px 0;
  display: flex;
  justify-content: center;
`;
