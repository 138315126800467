import styled from "styled-components";

export const Container = styled.footer`
  background-color: #f8f8f8;
  position: relative;
`;

export const Informations = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 20px;
  font-size: 12px;
  min-height: 150px;

  div {
    max-width: 1128px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 850px) {
      flex-direction: column;
      padding: 20px 0;
      gap: 20px;
    }

    nav {
      display: flex;
      gap: 20px;
      align-items: center;

      @media (max-width: 500px) {
        flex-direction: column;
        width: 100%;
        align-items: start;
      }
    }

    ul {
      list-style: none;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    li,
    a {
      display: flex;
      align-items: center;
      gap: 10px;
      text-decoration: none;
      color: #3c3c3b;
    }
  }
`;

export const Logo = styled.img`
  width: 232px;
  height: 62px;
`;

export const Companies = styled.div`
  background-color: #14202e;
  display: flex;
  justify-content: center;
  padding: 20px 0;
  min-height: 150px;

  div {
    max-width: 1128px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;

    @media (max-width: 850px) {
      flex-direction: column;
      gap: 20px;
    }

    figure {
      border-right: 1px solid white;
      width: 150px;
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        color: white;
        font-size: 12px;
      }

      @media (max-width: 850px) {
        border-right: none;
        justify-content: center;
        display: flex;
      }
    }

    ul {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      width: 80%;
      gap: 20px;
      justify-items: center;
      list-style: none;

      @media (max-width: 850px) {
        width: 100%;
        padding: 0 20px;
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
`;

export const YMK = styled.img`
  width: 108px;
  height: 91px;
`;

export const LogoCompanie = styled.img`
  width: 80px;
  height: 30px;
`;

export const Copyright = styled.div`
  display: flex;
  max-width: 1128px;
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 300;
  padding: 10px;
  color: #14202e;

  a {
    text-decoration: none;
    color: #14202e;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    font-size: 10px;
  }
`;
