import styled from "styled-components";

export const Container = styled.div`
  max-width: 360px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid #cfcfcf;
  padding-bottom: 10px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 18px;
  color: #3c3c3b;
`;

export const Image = styled.div`
  height: 200px;
  margin-bottom: 16px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
`;

export const Name = styled.span`
  color: #606060;
  font-weight: bold;
  font-size: 22px;
  line-height: 32px;
`;

export const Location = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: #606060;
`;

export const Info = styled.span`
  font-size: 14px;
`;

export const Price = styled.span`
  color: #606060;
  font-size: 15px;
  font-weight: bold;
  margin-top: 5px;
`;
