import { useState } from "react";
import { Link } from "react-router-dom";

import Sidebar from "../sidebar";
import Button from "../button";

import {
  Container,
  ContainerNavigation,
  ContainerSocialMedia,
  Logo,
  MenuMobile,
} from "./styles";

import { IoMenu } from "react-icons/io5";
import { FaWhatsapp, FaInstagram, FaFacebook } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";

import LogoEstoqueImovel from "../../assets/logo.svg";

const Header = ({ fixed }) => {
  const [sideBarShow, setSideBarShow] = useState(false);

  const showSideBar = () => {
    setSideBarShow(!sideBarShow);
  };

  return (
    <Container style={{ position: fixed && "fixed" }}>
      <ContainerSocialMedia>
        <nav>
          <ul>
            <li>
              <Link to="/sobre-nos" />
            </li>
            <li>
              <a
                href="https://wa.me/5511957860221"
                target="_blank"
                rel="noreferrer"
              >
                <FaWhatsapp color="#FC621F" />
                <span>Fale com um corretor</span>
              </a>
            </li>
            <li>
              <MdOutlineMail
                color="#FC621F"
                onClick={() =>
                  document.querySelector("footer").scrollIntoView({
                    behavior: "smooth",
                  })
                }
              />
              <span>Contato</span>
            </li>
            <li>
              <a
                href="https://www.instagram.com/estoqueimovel?igsh=eTQ0NDJ6c3R5cnB5"
                target="_blank"
                aria-label="instagram"
                rel="noreferrer"
              >
                <FaInstagram color="#FC621F" />
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/profile.php?id=61571778203027"
                target="_blank"
                aria-label="facebook"
                rel="noreferrer"
              >
                <FaFacebook color="#FC621F" />
              </a>
            </li>
          </ul>
        </nav>
      </ContainerSocialMedia>

      <ContainerNavigation>
        <Link to="/">
          <Logo src={LogoEstoqueImovel} alt="logo" />
        </Link>

        <nav>
          <ul>
            <li>
              <Link
                style={{
                  textDecoration: "none",
                  color: "#606060",
                  fontWeight: "bold",
                }}
                to="/imoveis/obras"
              >
                LANÇAMENTOS/OBRAS
              </Link>
            </li>

            <li>
              <Link
                style={{
                  textDecoration: "none",
                  color: "#606060",
                  fontWeight: "bold",
                }}
                to="/imoveis/pronto-para-morar"
              >
                PRONTO PARA MORAR
              </Link>
            </li>
            <li>
              <Link
                to="/sobre-nos"
                style={{
                  textDecoration: "none",
                  color: "#606060",
                  fontWeight: "bold",
                }}
              >
                QUEM SOMOS
              </Link>
            </li>
            <li>
              <Button borderRadius={5} width={200}>
                ÁREA DO CORRETOR
              </Button>
            </li>
          </ul>
        </nav>

        <MenuMobile>
          <IoMenu size={25} color="#FC621F" onClick={showSideBar} />
          {sideBarShow && (
            <Sidebar visible={sideBarShow} setVisible={setSideBarShow} />
          )}
        </MenuMobile>
      </ContainerNavigation>
    </Container>
  );
};

export default Header;
