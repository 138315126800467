import styled from "styled-components";

export const Container = styled.header`
  height: 150px;
  border-bottom: 1px solid #a4a4a4;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  width: 100%;
  background-color: white;
  top: 0;
`;

export const ContainerSocialMedia = styled.div`
  border-bottom: 1px solid #a4a4a4;
  height: 50px;
  display: flex;
  justify-content: center;
  width: 100%;

  nav {
    max-width: 1128px;
    width: 100%;
    display: flex;
    justify-content: end;

    @media (max-width: 1128px) {
      padding: 0 20px;
    }

    ul {
      display: flex;
      list-style: none;
      gap: 20px;
      align-items: center;
    }

    li,
    a {
      display: flex;
      align-items: center;
      cursor: pointer;
      gap: 10px;
      font-weight: 300;
      color: #606060;
      text-decoration: none;
    }

    span {
      @media (max-width: 425px) {
        display: none;
      }
    }
  }
`;

export const MenuMobile = styled.div`
  display: none;

  @media (max-width: 1000px) {
    display: flex;
  }
`;

export const ContainerNavigation = styled.div`
  max-width: 1128px;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1128px) {
    padding: 0 20px;
  }

  nav {
    @media (max-width: 1000px) {
      display: none;
    }

    ul {
      display: flex;
      list-style: none;
      gap: 20px;
      align-items: center;
    }
  }
`;

export const Logo = styled.img`
  width: 232px;
  height: 62px;
`;
