import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "../button";
import Location from "../location";

import { Container, ContainerSearch, Content, Input } from "./styles";
import Chat from "../chat";

const Banner = () => {
  const navigate = useNavigate();

  const [isModalOpen, setModalOpen] = useState(false);

  const [address, setAddress] = useState({
    neighborhood: "",
    city: "",
    state: "",
  });

  const handleSearch = () => {
    const { neighborhood, city, state } = address;
    navigate(
      `/buscar-imoveis?area=${neighborhood || ""}&city=${city || ""}&state=${
        state || ""
      }`
    );
  };

  const handleAddressChange = (address) => {
    setAddress(address);
  };

  return (
    <Container>
      <Content>
        <h1>O maior estoque de Imóveis do Brasil</h1>
        <h2>
          Somos a Ponta de Estoque de imóveis novos, em construção ou prontos,
        </h2>
        <h2> direto das incorporadoras e construtoras.</h2>

        <ContainerSearch>
          <Input>
            <Location onChange={handleAddressChange} />
            <Button
              icon="search"
              backgroundStyle=""
              borderRadius={60}
              width={200}
              onClick={handleSearch}
            />
          </Input>

          <Button
            icon="robot"
            height={48}
            borderRadius={60}
            onClick={() => setModalOpen(!isModalOpen)}
          >
            FALAR COM IA
          </Button>
          {isModalOpen && <Chat onClose={() => setModalOpen(!isModalOpen)} />}
        </ContainerSearch>
      </Content>
    </Container>
  );
};

export default Banner;
