import { useState } from "react";
import InputValidator from "@rcb-plugins/input-validator";
import ChatBot from "react-chatbotify";
import axios from "axios";

import api from "../../api";
import Card from "../card";

import {
  Container,
  Content,
  Data,
  Header,
  Icon,
  RenderCards,
  RenderChat,
} from "./styles";

import Logo from "../../assets/logo.svg";
import Close from "../../assets/icons/close.svg";
import LogoHeader from "../../assets/companies/estoqueImovel.svg";

const Chat = ({ onClose }) => {
  const [form, setForm] = useState({});
  const [cities, setCities] = useState();
  const [neighborhood, setNeighborhood] = useState([]);
  const [properties, setProperties] = useState([]);

  const plugins = [InputValidator()];

  const fetchNeighborhood = async (city) => {
    setForm({ ...form, city: city });

    try {
      const response = await api.get("/addresses/areas", {
        params: {
          state: "SP",
          city: city,
        },
      });

      setNeighborhood(response.data.areas);

      return true;
    } catch (error) {
      console.log("Erro: ", error);
      return false;
    }
  };

  const recaptcha = async () => {
    return new Promise((resolve, reject) => {
      if (!window.grecaptcha) {
        return reject(new Error("grecaptcha is not loaded"));
      }

      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute("6LeOXGMqAAAAAOEq2cllcTFEtbY7m0TKnRT8poQd", {
            action: "submit",
          })
          .then(resolve)
          .catch(reject);
      });
    });
  };

  const onSubmit = async () => {
    try {
      const token = await recaptcha();

      const requestData = {
        ...form,
        recaptchaToken: token,
        chat: "Estoque imóvel",
        id_produto: 22,
        midia: "Estoque Imóvel",
        link: properties.map(
          (item) => `https://estoqueimovel.com.br/imovel/${item.id}`
        ),
      };

      await axios.post("https://api.ymk.com.br/register", requestData);

      if (form.state !== "SP" || properties.length === 0) {
        setTimeout(() => {
          window.open("https://wa.me/5511957860221");
        }, 1000);
      }
    } catch (error) {
      console.error("Erro ao enviar o formulário:", error);
    }
  };

  const handleProperty = async () => {
    const data = {
      state: form.state,
      area: form.neighborhood.split(", "),
      status:
        form.propertyStatus === "Tanto faz"
          ? ["under_construction", "ready"]
          : form.propertyStatus === "Em obras"
          ? ["under_construction"]
          : ["ready"],
      bedrooms: [form.numberBedrooms],
      city: form.city,
    };

    if (form.numberBedrooms === "STUDIO") {
      data["type"] = ["studio"];
      delete data["bedrooms"];
    }

    if (form.price === "Acima de R$ 5 milhões") {
      data["min_price"] = parsePriceRange(form.price);
    } else {
      data["max_price"] = parsePriceRange(form.price);
    }

    try {
      const response = await api.get("/buildings", {
        params: data,
      });

      const properties = response.data.buildings || [];

      setProperties(properties);

      if (properties.length > 0) {
        return true;
      }

      return false;
    } catch (error) {
      console.log("Erro: ", error);
      return false;
    }
  };

  const parsePriceRange = (range) => {
    const match = range.match(/(\d+,\d+|\d+)\s*(milhão|milhões|mil)?/g);
    if (!match) return null;

    const toNumber = (str) => {
      if (str.includes("milhão") || str.includes("milhões"))
        return parseFloat(str.replace(",", ".")) * 1_000_000;
      if (str.includes("mil"))
        return parseFloat(str.replace(".", "").replace("mil", "")) * 1_000;
      return parseFloat(str.replace(".", ""));
    };

    const values = match.map(toNumber);

    if (range.includes("Acima")) {
      return values[0];
    }
    return values[values.length - 1];
  };

  const flow = {
    start: {
      message:
        "Olá, tudo bem? Vamos começar agora nossa busca pelo imóvel perfeito pra você!",
      path: "name",
      transition: { duration: 1000 },
    },

    name: {
      message: "Qual é o seu nome?",
      path: "states",
      function: (params) => setForm({ name: params.userInput }),
    },

    states: {
      message: (params) =>
        `Ótimo, ${params.userInput}! Em qual estado você quer comprar seu novo imóvel?`,

      options: ["SP", "RJ", "MG", "ES", "Outro"],
      chatDisabled: true,
      function: async (params) => {
        if (params.userInput === "Outro") {
          params.goToPath("listStates");
        } else if (params.userInput !== "SP") {
          setForm((form) => ({ ...form, state: params.userInput }));
          params.goToPath("email");
        } else {
          setForm((form) => ({ ...form, state: params.userInput }));

          try {
            const response = await api.get("/addresses/cities", {
              params: {
                state: "SP",
              },
            });

            const orderCities = response.data.cities.sort((a, b) => {
              if (a === "São Paulo" || a === "Campinas") return -1;
              if (b === "São Paulo" || b === "Campinas") return 1;
              return 0;
            });

            setCities(orderCities);
            params.goToPath("city");
          } catch (error) {
            console.log(error);
          }
        }
      },
    },

    listStates: {
      message: "Escolha o estado",
      options: [
        "AC",
        "AL",
        "AP",
        "AM",
        "BA",
        "CE",
        "DF",
        "GO",
        "MA",
        "MT",
        "MS",
        "PA",
        "PB",
        "PR",
        "PE",
        "PI",
        "RN",
        "RS",
        "RO",
        "RR",
        "SC",
        "SE",
        "TO",
      ],
      chatDisabled: true,
      function: (params) => {
        setForm((form) => ({ ...form, state: params.userInput }));
      },
      path: "email  ",
    },

    city: {
      message: "Escolha a cidade",
      options: [...(cities?.slice(0, 5) || []), "Outra"],
      chatDisabled: true,
      function: async (params) => {
        if (params.userInput === "Outra") {
          params.goToPath("otherCities");
        } else {
          const response = await fetchNeighborhood(params.userInput);

          if (response) {
            params.goToPath("neighborhood");
          }
        }
      },
    },

    otherCities: {
      message: "Escolha a cidade",
      options: cities?.slice(6),
      function: async (params) => {
        const response = await fetchNeighborhood(params.userInput);

        if (response) {
          params.goToPath("neighborhood");
        }
      },
    },

    neighborhood: {
      message: `Escolha o bairro em ${form.city}`,
      checkboxes: { items: neighborhood?.slice(10), min: 1 },
      chatDisabled: true,
      path: "numberBedrooms",
      function: (params) => {
        setForm((form) => ({ ...form, neighborhood: params.userInput }));
      },
    },

    numberBedrooms: {
      message: "Quantos dormitórios você precisa?",
      options: ["STUDIO", "1", "2", "3", "4+"],
      chatDisabled: true,
      path: "propertyStatus",
      function: (params) =>
        setForm((form) => ({
          ...form,
          numberBedrooms: params.userInput,
        })),
    },

    propertyStatus: {
      message:
        "O imóvel precisa estar pronto pra morar ou pode estar em obras?",
      options: ["Pronto para morar", "Em obras", "Tanto faz"],
      chatDisabled: true,
      path: "features",
      function: (params) =>
        setForm((form) => ({
          ...form,
          propertyStatus: params.userInput,
        })),
    },

    features: {
      message: "Algum item específico que você deseja?",
      options: [
        "LAZER COMPLETO",
        "LAZER NO ROOFTOP",
        "QUADRA DE TENIS",
        "QUADRA POLIESPORTIVA",
        "COWORKING",
        "SAUNA",
        "OUTRO",
      ],
      function: (params) => {
        if (params.userInput === "OUTRO") {
          params.goToPath("otherFeatures");
        } else {
          setForm({ ...form, features: params.userInput });

          params.goToPath("price");
        }
      },
      chatDisabled: true,
    },

    otherFeatures: {
      message: "Digite o item específico",
      function: (params) => {
        setForm({ ...form, features: params.userInput });
      },
      path: "price",
    },

    price: {
      message: `${form.name} creio que já tenho imóveis perfeitos para lhe mostrar. Mas antes, para sermos assertivos, até quanto você deseja investir?`,
      chatDisabled: true,
      path: "email",
      options: [
        "Até R$ 500 mil",
        "De R$ 500 mil até R$ 1 milhão",
        "De R$ 1 milhão até R$ 1,5 milhão",
        "De R$ 1,5 milhão até R$ 2 milhões",
        "De R$ 2 milhões até R$ 2,5 milhões",
        "De R$ 2,5 milhões até R$ 3 milhões",
        "De R$ 3 milhões até R$ 3,5 milhões",
        "De R$ 3,5 milhões até R$ 4 milhões",
        "De R$ 4 milhões até R$ 4,5 milhões",
        "De R$ 4,5 milhões até R$ 5 milhões",
        "Acima de R$ 5 milhões",
      ],
      function: (params) => {
        setForm({ ...form, price: params.userInput });
      },
    },

    email: {
      message: `${form.name} para eu te apresentar o imóvel perfeito pra você por favor me confirme seu melhor email`,
      path: "phone",
      function: (params) => {
        setForm({ ...form, email: params.userInput });
      },
    },

    phone: {
      message: "E seu telefone celular",
      function: (params) => {
        setForm({ ...form, phone: params.userInput });

        if (form.state !== "SP") {
          params.goToPath("redirect");
          return;
        }

        params.goToPath("data");
      },
      validateInput: (userInput) => {
        const phone = userInput.replace(/\D/g, "");

        if (phone.length !== 11) {
          return {
            success: false,
            promptContent: "Telefone incompleto",
            promptDuration: 1000,
            promptType: "error",
            highlightTextArea: true,
          };
        }

        if (typeof phone === "string" && !Number.isNaN(Number(phone))) {
          return { success: true };
        }
      },
    },

    data: {
      message: "Confirmando",
      component: (
        <Data>
          <p>
            <span>
              Status do imóvel: <strong>{form.propertyStatus}</strong>
            </span>
          </p>
          <p>
            <span>
              Bairro: <strong>{form.neighborhood}</strong>
            </span>
          </p>
          <p>
            <span>
              Número de quartos: <strong>{form.numberBedrooms} </strong>
            </span>
          </p>
          <p>
            <span>
              Preço: <strong>{form.price} </strong>
            </span>
          </p>
          <p>
            <span>
              Características: <strong>{form.features}</strong>
            </span>
          </p>
        </Data>
      ),
      transition: { duration: 1000 },
      path: "renderResults",
    },

    renderResults: {
      message:
        "Ótimo! Vou agora procurar o imóvel perfeito pra você. Isso pode levar alguns instantes.",
      chatDisabled: true,
      transition: { duration: 1000 },

      function: async (params) => {
        const response = await handleProperty();
        if (response) {
          params.goToPath("properties");
          return;
        }

        params.goToPath("noResults");
      },
    },

    noResults: {
      message: "Não encontrei nenhum imóvel",
      transition: { duration: 3000 },
      path: "redirect",
    },

    properties: {
      message: "Estes são os imóveis encontrados: ",
      component: (
        <div>
          {properties.map((property) => {
            const {
              id,
              name,
              default_image,
              address,
              min_area,
              min_parking,
              min_bedrooms,
              min_price,
            } = property;

            return (
              <RenderCards>
                <Card
                  key={id}
                  name={name}
                  image={default_image["520x280"]}
                  dormitory={min_bedrooms}
                  location={address.area}
                  parkingSpaces={min_parking}
                  price={min_price}
                  squareMeters={min_area}
                  suite={0}
                  card="default"
                  onClick={() =>
                    window.open(`https://estoqueimovel.com.br/imovel/${id}`)
                  }
                />
              </RenderCards>
            );
          })}
        </div>
      ),
      function: async () => onSubmit(),
      transition: { duration: 3000 },
    },

    redirect: {
      message: "Estou te encaminhando para um consultor",
      chatDisabled: true,
      transition: { duration: 3000 },
      function: async () => await onSubmit(),
    },
  };

  return (
    <Container>
      <Content>
        <Header>
          <img src={Logo} alt="logo" />
          <div onClick={onClose}>
            <h3>Fechar</h3>
            <Icon src={Close} />
          </div>
        </Header>

        <RenderChat>
          <ChatBot
            settings={{
              emoji: true,
              fileAttachment: true,
              general: {
                embedded: true,
                primaryColor: "#fc5c20 ",
                secondaryColor: "#ff801e",
              },
              header: {
                avatar: LogoHeader,
                title: "Estoque Imóvel",
              },
              footer: {
                text: "",
              },
              chatInput: {
                enabledPlaceholderText: "",
              },
              notification: {
                disabled: true,
              },
              userBubble: {
                showAvatar: true,
              },

              chatHistory: {
                disabled: true,
              },
            }}
            plugins={plugins}
            flow={flow}
          />
        </RenderChat>
      </Content>
    </Container>
  );
};

export default Chat;
