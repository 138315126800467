import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";

import api from "../../api";
import Card from "../card";
import Button from "../button";

import { Cards, Container, ContainerButton, Filter } from "./styles";

const PropertyList = ({ queryKey }) => {
  const navigate = useNavigate();

  const { register, watch } = useForm();

  const query = new URLSearchParams(useLocation().search);

  const order = watch("order");

  const filter = [
    "area",
    "city",
    "state",
    "status",
    "type",
    "reference",
    "max_price",
    "max_private_area",
    "price_order",
    "area_order",
    "last_updated_date_order",
  ].reduce((params, key) => {
    const queryFilter = query.get(key);

    if (queryFilter !== null && queryFilter !== "") {
      params[key] = queryFilter;
    }

    return params;
  }, {});

  const fetchProperties = async ({ pageParam = 1 }) => {
    const data = {
      results_per_page: 12,
      page: pageParam,
      ...filter,
    };

    if (queryKey === "obras") {
      data["status"] = ["under_construction"];
    } else if (queryKey === "pronto-para-morar") {
      data["status"] = ["ready"];
    }

    const filteredData = Object.fromEntries(
      Object.entries(data).map(([key, value]) => {
        if (["area", "type", "status"].includes(key)) {
          return [key, Array.isArray(value) ? value : value.split(",")];
        }
        return [key, value];
      })
    );

    const response = await api.get("buildings", {
      params: filteredData,
    });

    return {
      buildings: response.data.buildings || [],
      totalPages: response.data.total_pages || 1,
      page: pageParam,
    };
  };

  const {
    data = { pages: [] },
    isLoading,
    error,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: [queryKey, filter],
    queryFn: fetchProperties,
    getNextPageParam: (lastPage) =>
      lastPage.page < lastPage.totalPages ? lastPage.page + 1 : undefined,
  });

  useEffect(() => {
    if (!order) return;

    query.delete("price_order");
    query.delete("area_order");
    query.delete("last_updated_date_order");

    if (order === "price_asc" || order === "price_desc") {
      query.set("price_order", order === "price_asc" ? "asc" : "desc");
    } else if (order === "area_asc" || order === "area_desc") {
      query.set("area_order", order === "area_asc" ? "asc" : "desc");
    } else {
      query.set("last_updated_date_order", "desc");
    }

    navigate({
      pathname: window.location.pathname,
      search: query.toString(),
    });
  }, [order]);

  return (
    <Container>
      <Filter>
        Ordenar por:
        <select {...register("order")}>
          <option value="">Selecione</option>
          <option value="price_asc">Menor preço</option>
          <option value="price_desc">Maior preço</option>
          <option value="area_desc">Maior área</option>
          <option value="area_asc">Menor área</option>
          <option value="recent_last_updated_date_order">Mais recente</option>
        </select>
      </Filter>

      {!isLoading && !error && data?.pages[0]?.buildings.length === 0 && (
        <p>Nenhum imóvel encontrado</p>
      )}

      {isLoading && <p>Carregando...</p>}

      {error && <p>Erro ao carregar imóveis</p>}

      <Cards $highlights={queryKey === "destaques"}>
        {data.pages &&
          Array.isArray(data.pages) &&
          data.pages.flatMap((page, pageIndex) =>
            page.buildings.map((item, index) => (
              <Card
                key={`${item.id}-${pageIndex * 1000 + index}`}
                name={item.name}
                image={item.default_image["520x280"]}
                dormitory={item.min_bedrooms}
                location={item.address.area}
                parkingSpaces={item.min_parking}
                price={item.min_price}
                squareMeters={item.min_area || 0}
                suite={0}
                card={queryKey === "destaques" ? "" : "default"}
                onClick={() => navigate(`/imovel/${item.id}`)}
              />
            ))
          )}
      </Cards>

      {hasNextPage && (
        <ContainerButton>
          <Button
            onClick={fetchNextPage}
            disabled={isFetchingNextPage}
            background={queryKey === "destaques" && "orange"}
          >
            {isFetchingNextPage ? "Carregando..." : "Carregar mais imóveis"}
          </Button>
        </ContainerButton>
      )}
    </Container>
  );
};

export default PropertyList;
