import Footer from "../../components/footer";
import Header from "../../components/header";
import Title from "../../components/title";

import {
  Container,
  ContainerDescription,
  Content,
  Description,
  Image,
  Informations,
} from "./styles";

import ImageBroker from "../../assets/broker.jpg";

const AboutUs = () => {
  return (
    <Container>
      <Header />
      <Content>
        <Informations>
          <Title fontSize={39}>Conectando Você às Melhores Oportunidades</Title>

          <ContainerDescription>
            <Image src={ImageBroker} alt="imagem" />

            <Description>
              <p style={{}}>
                {`Somos a Estoque Imóvel, a maior ponta de estoque de imóveis
                novos, em construção ou prontos da cidade de São Paulo.\n\n
                
                Nossa missão é simples: conectar você às melhores oportunidades
                do mercado imobiliário de forma rápida, prática e segura.\n\n
               
                Com acesso exclusivo a um vasto banco de imóveis de mais de 160
                construtoras parceiras, criamos um processo pensado para
                facilitar sua jornada:`}
              </p>
              <br />
              <br />
              <ol>
                <li>
                  Você nos conta o que procura — tamanho, dormitórios, valor,
                  localização.
                </li>
                <li>Nós buscamos as melhores opções no mercado.</li>
                <li>Você escolhe os imóveis que deseja visitar.</li>
                <li>Decidiu? Nós negociamos diretamente com a construtora.</li>
                <li>Fechamos o melhor negócio para você!</li>
              </ol>
              <br />
              Aqui, sua busca pelo imóvel perfeito é ágil e personalizada,
              garantindo que você tenha acesso às melhores condições antes de
              tomar sua decisão.
              <br />
              <br />
              <strong>Não compre sem antes nos consultar.</strong>
              <br />
              <br />
              Estoque Imóvel — uma empresa do Grupo YMK.
            </Description>
          </ContainerDescription>
        </Informations>
      </Content>

      <Footer />
    </Container>
  );
};

export default AboutUs;
