import styled from "styled-components";

export const Container = styled.section``;

export const Content = styled.div`
  max-width: 1128px;
  width: 100%;
  margin: 0 auto;
  padding: 35px 0;

  @media (max-width: 1128px) {
    padding: 0 20px;
  }
`;
