import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  height: 100%;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  padding: 0 20px;
`;

export const Informations = styled.div`
  width: 100%;
  max-width: 1128px;
  padding: 35px 0;
`;

export const ContainerDescription = styled.div`
  display: flex;
  gap: 72px;

  @media (max-width: 960px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Image = styled.img`
  max-width: 50%;
  object-fit: contain;

  @media (max-width: 960px) {
    max-width: 100%;
  }
`;

export const Description = styled.div`
  font-size: 14px;
  color: #606060;

  ol {
    margin-left: 20px;
  }
`;

export const ContainerInformations = styled.div``;
