import styled from "styled-components";

export const Container = styled.div`
  min-height: 500px;
`;

export const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;

  @media (max-width: 900px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 650px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 425px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const ContainerButton = styled.div`
  padding: 30px 0;
  display: flex;
  justify-content: center;
`;

export const Filter = styled.div`
  margin-bottom: 30px;
  display: flex;
  gap: 10px;

  select {
    border: none;
    cursor: pointer;
    outline: none;
  }
`;
