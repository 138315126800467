import styled from "styled-components";

export const Container = styled.div`
  max-width: 500px;
  width: 100%;
`;

export const Input = styled.input`
  font-size: 24px;
  color: #969696;
  height: 50px;
  border: none;
  outline: none;
  width: 100%;

  &::placeholder {
    font-weight: 300;
    font-size: 20px;
  }
`;
