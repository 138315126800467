import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import Footer from "../../components/footer";
import Header from "../../components/header";
import InfosRealestate from "./informationsRealestate";
import api from "../../api";

import { Container, Content, LoadingMessage } from "./styles";

const RenderInformations = () => {
  const { id } = useParams();

  const fetchRealestate = async () => {
    const response = await api.get(`/buildings/${id}`);
    return response.data;
  };

  const {
    data = {},
    error,
    isLoading,
  } = useQuery({
    queryKey: ["building", id],
    queryFn: fetchRealestate,
  });

  return (
    <Container>
      <Header />
      <Content>
        {isLoading && <LoadingMessage>Carregando...</LoadingMessage>}
        {error && (
          <LoadingMessage>
            Erro ao carregar dados: {error.message}
          </LoadingMessage>
        )}

        {!isLoading && !error && (
          <InfosRealestate
            name={data.name || ""}
            location={data.address?.area || "Localização não disponível"}
            value={(data.min_price || 0).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })}
            squareMeters={data.min_area || ""}
            suite={data.min_suites || 0}
            parkingSpaces={data.min_parking || 0}
            bedroom={data.min_bedrooms || 0}
            description={data.description || "Descrição não disponível"}
            latitude={data.address?.latitude || 0}
            longitude={data.address?.longitude || 0}
            bathrooms={data.min_bathrooms || 0}
            stage={data.stage || "Não especificado"}
            launch_date={data.launch_date || "Data não informada"}
            opening_date={data.opening_date || "Data não informada"}
            total_units={data.total_units || 0}
            features={data.features || []}
          />
        )}
      </Content>
      <Footer />
    </Container>
  );
};

export default RenderInformations;
