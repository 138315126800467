import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 20px;
  position: relative;
`;

export const Content = styled.div`
  max-width: 1128px;
  width: 100%;
  padding-top: 35px;
  display: flex;
  flex-direction: column;
  margin-bottom: 59px;
`;

export const Title = styled.h1`
  font-size: 32px;
  color: #ff4900;
  margin-bottom: 26px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const FiltersRow = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ContainerButton = styled.div`
  max-width: 256px;
  width: 100%;
  margin: 0 auto;
`;
