import {
  Container,
  Content,
  Image,
  Info,
  Location,
  Name,
  Price,
} from "./styles";

const Card = ({
  image,
  name,
  location,
  price,
  squareMeters,
  parkingSpaces,
  dormitory,
  onClick,
  suite,
  card = "",
  width,
  height,
}) => {
  return (
    <Container onClick={onClick} style={{ width: width, height: height }}>
      <Image
        alt="imovel"
        style={{
          backgroundImage: `url("${image}")`,
          borderBottom: card !== "default" && "5px solid #606060",
        }}
      />

      <Content>
        <Name
          style={{
            fontSize: card !== "default" && 32,
          }}
        >
          {name}
        </Name>
        <Location>{location}</Location>

        <Info>{squareMeters} m²</Info>

        {parkingSpaces !== 0 && <Info>{parkingSpaces} vaga(s)</Info>}
        {suite !== 0 && <Info>{suite} suíte(s)</Info>}
        {dormitory !== 0 && <Info>{dormitory} dormitório(s)</Info>}

        {price !== 0.1 && (
          <>
            <span style={{ fontSize: 13 }}>A partir de</span>

            <Price
              style={{
                color: card !== "default" && "#FC621F",
                fontWeight: card !== "default" && "900",
                fontSize: card !== "default" && 24,
              }}
            >
              {price.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })}
            </Price>
          </>
        )}
      </Content>
    </Container>
  );
};

export default Card;
