import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  label {
    font-size: 13px;
    color: #3c3c3b;
  }

  select,
  input {
    height: 40px;
    border-radius: 4px;
    padding-left: 10px;
    border: 1px solid #d9d9d9;
  }
`;
