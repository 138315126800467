import styled from "styled-components";

import Background from "../../assets/banner.webp";

export const Container = styled.section`
  background-image: url(${Background});
  min-height: 605px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  background-position: 80%;
  margin-top: 150px;
`;

export const Content = styled.div`
  max-width: 1128px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  text-align: center;
  padding: 0 30px;

  h1 {
    font-size: 60px;
    color: white;

    @media (max-width: 425px) {
      font-size: 30px;
    }
  }

  h2 {
    font-size: 24px;
    color: white;
    @media (max-width: 425px) {
      font-size: 17px;
    }
  }
`;

export const ContainerSearch = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }
`;

export const Input = styled.div`
  max-width: 736px;
  width: 100%;
  border-radius: 60px;
  height: 59px;
  border: 1px solid #d9d9d9;
  font-size: 24px;
  color: #969696;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 0 20px;
  justify-content: space-between;

  button {
    &::before {
      content: "BUSCAR IMÓVEIS";

      @media (max-width: 1090px) {
        content: "BUSCAR";
      }
    }
  }
`;
