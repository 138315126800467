import { ContainerButton, Icon } from "./styles";
import { IoSearch } from "react-icons/io5";
import IconRobot from "../../assets/icons/robot.svg";

const icons = {
  search: <IoSearch size={25} color="white" />,
  robot: <Icon src={IconRobot} alt="icone" />,
};

const Button = ({
  children,
  icon,
  disabled,
  onClick,
  backgroundStyle = true,
  width,
  borderRadius,
  height,
}) => {
  const background = backgroundStyle
    ? "linear-gradient(90deg, #FC5C20 0%, #FF821D 100%)"
    : "#FC5C20";

  return (
    <ContainerButton
      style={{
        background: background,
        width: width,
        borderRadius: borderRadius,
        height: height,
      }}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
      {icon && icons[icon]}
    </ContainerButton>
  );
};

export default Button;
