import React from "react";
import styled from "styled-components";

const BorderContainer = styled.div`
  position: absolute;
  width: 100%;
`;

const ColorBorder = styled.div`
  height: 5px;
  background: linear-gradient(to left, #ff821d 0%, #fc5c20 100%);
`;

const Border = ({ children, bottom, top }) => {
  return (
    <BorderContainer style={{ bottom: bottom, top: top }}>
      <ColorBorder />
      {children}
    </BorderContainer>
  );
};

export default Border;
