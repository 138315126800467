import { Container } from "./styles";

const Status = ({ register }) => {
  return (
    <Container>
      <label>Status</label>

      <div>
        <input
          type="checkbox"
          value="under_construction"
          {...register("status")}
        />
        <label>Em obras</label>

        <input type="checkbox" value="ready" {...register("status")} />
        <label>Pronto para morar</label>
      </div>
    </Container>
  );
};

export default Status;
