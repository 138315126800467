import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import ScrollToTop from "../components/scrollToTop";
import Home from "./home";
import AboutUs from "./aboutUs";
import PrivacyPolicy from "./privacyPolicy";
import SearchResults from "./searchResults";
import RenderCategory from "./renderCategory";
import RenderInformations from "./renderInformations";

export const Root = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="sobre-nos" element={<AboutUs />} />
        <Route path="/politica-de-privacidade" element={<PrivacyPolicy />} />
        <Route path="/buscar-imoveis" element={<SearchResults />} />
        <Route path="/imoveis/:name" element={<RenderCategory />} />
        <Route path="/imovel/:id" element={<RenderInformations />} />
      </Routes>
    </Router>
  );
};

export default Root;
