import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  height: 100%;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  padding: 35px 20px;
`;

export const ContainerInformations = styled.div`
  max-width: 1128px;
  width: 100%;

  p,
  li {
    font-size: 14px;
    color: #3c3c3b;
  }

  li {
    margin-left: 20px;
  }
`;
