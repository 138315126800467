import {
  Companies,
  Container,
  Copyright,
  Informations,
  Logo,
  LogoCompanie,
  YMK,
} from "./styles";

import Border from "../border";

import {
  FaPhoneAlt,
  FaWhatsapp,
  FaFacebook,
  FaInstagram,
} from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import { IoLocationSharp } from "react-icons/io5";

import LogoYMK from "../../assets/companies/ymk.svg";
import EstoqueImovel from "../../assets/companies/estoqueImovel.svg";
import Bladoom from "../../assets/companies/bladoom.svg";
import Urblar from "../../assets/companies/urblar.svg";
import Credimov from "../../assets/companies/credimov.svg";
import Investimov from "../../assets/companies/investimov.svg";
import Rai from "../../assets/companies/rai.svg";
import YmkAgro from "../../assets/companies/ymkAgro.svg";
import YmkCapital from "../../assets/companies/ymkCapital.svg";
import YmkCorp from "../../assets/companies/ymkCorp.svg";
import YmkLand from "../../assets/companies/ymkLand.svg";
import Realestate from "../../assets/companies/realestate4me.svg";
import LogoEstoqueImovel from "../../assets/logo.svg";
import Singular from "../../assets/companies/singular.svg";

const companies = [
  {
    src: EstoqueImovel,
    alt: "Estoque Imóvel",
    link: "https://www.estoqueimovel.com.br/",
  },
  { src: Bladoom, alt: "Bladoom", link: "https://bladoom.com/" },
  { src: Urblar, alt: "Urblar", link: "https://urblar.com.br/" },
  { src: Credimov, alt: "Credimov", link: "https://credimov.com.br/" },
  { src: Investimov, alt: "Investimov", link: "http://investimov.com.br/" },
  { src: Rai, alt: "Rai", link: "https://rainvestimentos.com.br/" },
  { src: YmkAgro, alt: "YMK Agro", link: "https://www.ymk.com.br/" },
  { src: YmkCapital, alt: "YMK Capital", link: "https://www.ymk.com.br/" },
  { src: YmkCorp, alt: "YMK Corp", link: "https://www.ymkcorp.com.br/" },
  { src: YmkLand, alt: "YMK Land", link: "https://www.ymkland.com.br/" },
  {
    src: Realestate,
    alt: "Real Estate 4 Me",
    link: "https://realestate4me.com.br/",
  },
  {
    src: Singular,
    alt: "Singular realty",
    link: "https://singularrealty.com.br/",
  },
];

const Footer = () => {
  return (
    <Container id="footer">
      <Border top={0} />
      <Informations>
        <div>
          <Logo src={LogoEstoqueImovel} alt="logo" />

          <nav>
            <ul>
              <li>
                <FaPhoneAlt color="#FF4900" />
                (11) 3995-7808
              </li>
              <li>
                <a
                  href="https://wa.me/5511957860221"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaWhatsapp color="#FF4900" />
                  (11) 95786-0221
                </a>
              </li>
              <li>
                <MdOutlineMail color="#FF4900" />
                <a href="mailto: contato@estoqueimovel.com.br">
                  contato@estoqueimovel.com.br
                </a>
              </li>
            </ul>

            <ul>
              <li>
                <IoLocationSharp color="#FF4900" />
                Praça Santo Agostinho, 70 <br /> Aclimação - São Paulo - SP CEP
                01533-070
              </li>
            </ul>

            <ul>
              <li>
                <a
                  href="https://www.instagram.com/estoqueimovel?igsh=eTQ0NDJ6c3R5cnB5"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaInstagram color="#FF4900" />
                  estoqueimovel
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/profile.php?id=61571778203027"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaFacebook color="#FF4900" />
                  estoqueimovel
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </Informations>

      <Companies>
        <div>
          <figure>
            <a
              href="https://ymk.com.br/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <YMK src={LogoYMK} alt="logo" />
            </a>
            <span>CRECI J-29301</span>
          </figure>

          <ul>
            {companies.map((company, index) => (
              <li key={index}>
                <a
                  href={company.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LogoCompanie src={company.src} alt={company.alt} />
                </a>
              </li>
            ))}
          </ul>
        </div>
      </Companies>

      <Copyright>
        <div>
          &copy; {new Date().getFullYear()} ESTOQUE IMÓVEL | TODOS DIREITOS
          RESERVADOS
        </div>
        <div>
          Desenvolvido por{" "}
          <a
            target="_blank"
            aria-label="ypb"
            rel="noopener noreferrer"
            href="https://ypb.com.br/"
          >
            YPB MARKETING DIGITAL
          </a>
        </div>
      </Copyright>
    </Container>
  );
};

export default Footer;
