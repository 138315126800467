import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  justify-content: center;
  background-color: #f8f8f8;
  min-height: 500px;
  padding: 20px 20px;
`;

export const Content = styled.div`
  max-width: 1128px;
  width: 100%;
`;

export const ContainerCards = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    > div:nth-child(3):nth-last-child(1) {
      @media (max-width: 500px) {
        display: flex;
      }
      display: none;
    }
  }

  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: center;
  padding: 30px 0;
`;
