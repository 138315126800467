import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 37px;

  h1 {
    color: #fc621f;
    font-size: 40px;
    line-height: 45px;
  }

  h2 {
    color: #3c3c3b;
    font-size: 16px;
    font-weight: normal;
  }
`;
